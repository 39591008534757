import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class DetalleProyectoService {

  env = environment;
  constructor(
    private http: HttpClient
  ) { }

  ///////////////////////Busca codificacion
  readCod(proyecto: number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.get(`${ this.env.api }/codificacion/${proyecto}`, { headers });
  }
  
  ///////////////////////Edita proyecto
  updateProyecto(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.put(`${ this.env.api }/proyecto`, body, { headers });
  }

  ///////////////////////Elimina proyecto
  deleteProyecto(id: number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.delete(`${ this.env.api }/proyecto/${id}`, { headers });
  }

  ///////////////////////Deshabilita proyecto
  disableProyecto(id: number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    let body = {
      id: id
    };

    return this.http.patch(`${ this.env.api }/proyecto`, body, { headers });
  }

  ///////////////////////Crea codificacion
  createCod(name: string, descripcion: string, proyecto: number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });
    
    let body = {
      nombre: name,
      descripcion: descripcion,
      proyecto: proyecto
    };

    return this.http.post(`${ this.env.api }/codificacion`, body, { headers });
  }

  ///////////////////////Edita codificacion
  updateCod(name: string, descripcion: string, codificacion: number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });
    
    let body = {
      nombre: name,
      descripcion: descripcion,
      codificacion: codificacion
    };

    return this.http.put(`${ this.env.api }/codificacion`, body, { headers });
  }

  ///////////////////////Elimina codificacion
  deleteCod(id: number){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.delete(`${ this.env.api }/codificacion/${id}`, { headers });
  }


  ///////////////////////Busca codificaciones a descargar
  downloadProyecto(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.post(`${ this.env.api }/proyecto/descarga`, body, { headers });
  }

  ///////////////////////Busca codificaciones a descargar
  countProyecto(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.post(`${ this.env.api }/proyecto-pag/registro-count`, body, { headers });
  }


  ///////////////////////Busca codificaciones a descargar
  downloadProyectoPag(body: any){
    let idToken = localStorage.getItem('idToken');
    const headers = new HttpHeaders({
      'Authorization': idToken
    });

    return this.http.post(`${ this.env.api }/proyecto-pag/descarga-pag`, body, { headers });
  }


}
